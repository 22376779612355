.banner-card::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}

.banner-card:hover::after {
    background-color: rgba(0, 0, 0, 0.22);
}

.card-title {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
}
